export default {
    name: 'ArchiveCount',

    data() {
        return {
            category_id_sub_sub:'',
            query: {},
            staffColumn: [
                {
                    title: '员工姓名',
                    key: 'name'
                },
                {
                    title: '生日日期',
                    key: 'date'
                }
            ],
            staff: [],
            birthday_total:0,
            birthday_page:1,
            birthday_page_num:16,
            birthday_type:0,



            contractColumn: [
                {
                    title: '合同名称',
                    key: 'name'
                },
                {
                    title: '到期日期',
                    key: 'date'
                }
            ],
            contract: [],
            contract_total:0,
            contract_page:1,
            contract_page_num:16,

            selected:"-1",
            selectedSub:"-1",
            selectedSubSub:"-1",

            category_id:null,
            category_id_sub:null,
            contractSelect:[],
            contractSelectSub:[],
            contractSelectSubSub:[],





            retireColumn: [
                {
                    title: '员工姓名',
                    key: 'name'
                },
                {
                    title: '退休日期',
                    key: 'date'
                }
            ],
            retire: [],
            retire_type:0,
            retire_total:0,
            retire_page:1,
            retire_page_num:16,

            archives_all:0,
            archives_add:0,

        }
    },
    mounted() {
        this.getSata();
        this.getBirthday();
        this.getExpire();
        this.getRetire();
        this.getCategory()

    },
    watch: {
        selected:function(num,old){
            if(num<0){
                this.category_id=0;
                this.category_id_sub=0;
                this.category_id_sub_sub=0;
                this.selectedSub='-1';
                this.selectedSubSub='-1';
                this.getExpire();
                return;
            }
            this.category_id=this.contractSelect[num].category_key;
            this.contractSelectSub=this.contractSelect[num].category_sub;
            this.selectedSub='-1';
            this.selectedSubSub='-1';
            this.category_id_sub=0;
            this.category_id_sub_sub=0;
            this.getExpire();
        },
        selectedSub:function(num,old){
            if(num<0){
                this.category_id_sub=0;
                this.category_id_sub_sub=0;
                this.getExpire();
                return;
            }
            if(!num&&num!=0){
                return;
            }
            // this.contractSelect[num].category_sub;
            this.category_id_sub=this.contractSelectSub[num].category_id

            this.contractSelectSubSub=this.contractSelectSub[num].category_sub
            this.selectedSubSub='-1';
            this.category_id_sub_sub=0;

            this.getExpire();
            // this.contractSelectSub=this.contractSelect[num].category_sub
        },
        selectedSubSub:function(num,old){
            if(num<0){
                this.category_id_sub_sub=0;
                this.getExpire();
                return;
            }
            if(!num&&num!=0){
                return;
            }
            this.category_id_sub_sub= this.contractSelectSubSub[num].category_id
            this.getExpire();
        },
        birthday_type:function(num,old){
            this.getBirthday()
        },
        retire_type:function(num,old){
            this.getRetire()
        }
    },

    methods: {
        changePage(num){
            this.birthday_page=num;
            this.getBirthday();
        },
        changePage2(num){
            this.contract_page=num;
            this.getExpire();
        },
        changePage3(num){
            this.retire_page=num;
            this.getRetire();
        },
        archives_detail(){
            this.$router.push({path:'/count/detail'});
        },
        getCategory(){
            this.$api.archives.category({
                category_tag:''
            }).then(res => {
                if (res.status_code != 0) return this.$Message.warning({content: res.status_msg})
                // this.contractSelect=res.list[0].category_sub;
                res.list.pop()
                this.contractSelect=res.list;

            }).catch(err => {
                console.log(err)
            })
        },
        getSata(){
            this.$api.archives.stat().then(res => {
                if (res.status_code != 0) return this.$Message.warning({content: res.status_msg})
                this.archives_add=res.obj.add_num;
                this.archives_all=res.obj.total_num;
            }).catch(err => {
                console.log(err)
            })
        },
         // 跳转详情
         handleRowClick(row) {
            // this.$router.push({ name: 'RetrievalDetail', query: { type: row.archive_type, code: row.archives_code } })
            const newTag = this.$router.resolve({name: 'RetrievalDetail', query: { type: row.archive_type, code: row.archive_code }})
            // return;
            window.open(newTag.href,'_blank');




        },

        // 获取员工生日
        getBirthday(){
            this.$api.archives.birthday({
                type:this.birthday_type,
                page:this.birthday_page,
                page_number:this.birthday_page_num,
            }).then(res => {
                if (res.status_code != 0) return this.$Message.warning({content: res.status_msg})

                this.birthday_total=res.obj.total;
                this.birthday_page=res.obj.page;
                // this.birthday_total=res.obj.total_page;
                this.staff=res.list;
            }).catch(err => {
                console.log(err)
            })
        },
        // 获取合同到期
        getExpire(){
            this.$api.archives.expire({
                page:this.contract_page,
                page_number:this.contract_page_num,
                archive_type:this.category_id,
                second_category_id:Number(this.category_id_sub) ,
                third_category_ids:Number(this.category_id_sub_sub),
            }).then(res => {
                if (res.status_code != 0) return this.$Message.warning({content: res.status_msg})

                this.contract_total=res.obj.total;
                this.contract_page=res.obj.page;
                this.contract=res.list;
            }).catch(err => {
                console.log(err)
            })
        },

        // 获取退休
        getRetire(){
            this.$api.archives.retire({
                type:this.retire_type,
                page:this.retirepage,
                page_number:this.retire_page_num,
            }).then(res => {
                if (res.status_code != 0) return this.$Message.warning({content: res.status_msg})
                this.retire_total=res.obj.total;
                this.retire_page=res.obj.page;
                this.retire_total=res.obj.total_page;
                this.retire=res.list;
            }).catch(err => {
                console.log(err)
            })
        },
        changePage(v) {

        },
        toUserDetail(){
            this.$router.push({path:'/count/detail'})
        }
    }
}